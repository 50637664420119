import * as React from "react";
import { StyledEngineProvider } from '@material-ui/core/styles';

import Layout from '../components/Layout';

export default function Blog() {
  return (
    <StyledEngineProvider injectFirst>
      <Layout subtitle="Blog">
      </Layout>
    </StyledEngineProvider>
  );
}